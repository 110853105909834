export default {
  'common': {
    'add': 'أضف',
    'cancel': 'إلغاء',
    'description': 'وصف',
    'delete': 'حذف',
    'title': 'عنوان',
    'save': 'حفظ',
    'faq': 'التعليمات',
    'contact': 'اتصل بنا',
    'tos': 'شروط الخدمة',
    'policy': 'سياسة الخصوصية'
  },
  'board': {
    'titlePlaceholder': 'أدخل عنوانًا لهذه البطاقة',
    'deleteDescription': 'هل أنت متأكد أنك تريد حذف هذه البطاقة؟',
    'editCard': 'تحرير البطاقة',
    'deleteCard': 'حذف البطاقة',
    'state': {
      'TODO': 'لكى يفعل',
      'INPROGRESS': 'في تقدم',
      'TESTING': 'اختبارات',
      'DONE': 'منجز'
    }
  },
  'chat': {
    'online': 'المستخدمون المتصلون ({count})',
    'addChannel': 'أضف قناة',
    'channel': 'القناة | القنوات',
    'message': 'رسالة'
  },
  'email': {
    'compose': 'إنشاء بريد إلكتروني',
    'send': 'إرسال',
    'subject': 'موضوع',
    'labels': 'ملصقات',
    'emptyList': 'قائمة البريد الإلكتروني فارغة',
    'inbox': 'صندوق الوارد',
    'sent': 'أرسلت',
    'drafts': 'المسودات',
    'starred': 'مميز بنجمة',
    'trash': 'قمامة، يدمر، يهدم',
    'work': 'عمل',
    'invoice': 'فاتورة'
  },
  'todo': {
    'addTask': 'إضافة مهمة',
    'tasks': 'مهام',
    'completed': 'منجز',
    'labels': 'ملصقات'
  },
  'dashboard': {
    'activity': 'نشاط',
    'weeklySales': 'المبيعات الأسبوعية',
    'sales': 'مبيعات',
    'recentOrders': 'الطلبيات الأخيرة',
    'sources': 'مصادر حركة المرور',
    'lastweek': 'مقابل الأسبوع الماضي',
    'orders': 'الطلب #٪ s',
    'customers': 'العملاء',
    'tickets': 'تذاكر الدعم الفني',
    'viewReport': 'عرض التقرير'
  },
  'usermenu': {
    'profile': 'الملف الشخصي',
    'signin': 'تسجيل الدخول',
    'dashboard': 'لوحة القيادة',
    'signout': 'خروج'
  },
  'error': {
    'notfound': 'الصفحة غير موجودة',
    'other': 'خطأ ocurred ل'
  },
  'check': {
    'title': 'تعيين كلمة مرور جديدة',
    'backtosign': 'العودة إلى تسجيل الدخول',
    'newpassword': 'كلمة مرور جديدة',
    'button': 'قم بتعيين كلمة مرور جديدة وقم بتسجيل الدخول',
    'error': 'ارتباط الإجراء غير صالح',
    'verifylink': 'التحقق من الارتباط ...',
    'verifyemail': 'التحقق من عنوان البريد الإلكتروني ...',
    'emailverified': 'تم التحقق من البريد الإلكتروني! إعادة توجيه...'
  },
  'forgot': {
    'title': 'هل نسيت كلمة المرور؟',
    'subtitle': 'أدخل عنوان البريد الإلكتروني لحسابك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.',
    'email': 'البريد الإلكتروني',
    'button': 'اعادة تعيين كلمة المرور طلب',
    'backtosign': 'العودة إلى تسجيل الدخول'
  },
  'login': {
    'title': 'تسجيل الدخول',
    'email': 'البريد الإلكتروني',
    'password': 'كلمه السر',
    'button': 'تسجيل الدخول',
    'orsign': 'أو تسجيل الدخول باستخدام',
    'forgot': 'هل نسيت كلمة المرور؟',
    'noaccount': 'ليس لديك حساب؟',
    'create': 'قم بإنشاء واحدة هنا',
    'error': 'تركيبة البريد الإلكتروني / كلمة المرور غير صالحة'
  },
  'register': {
    'title': 'إنشاء حساب',
    'name': 'الاسم بالكامل',
    'email': 'البريد الإلكتروني',
    'password': 'كلمه السر',
    'button': 'إنشاء حساب',
    'orsign': 'أو اشترك مع',
    'agree': 'بالتسجيل ، فإنك توافق على',
    'account': 'هل لديك حساب؟',
    'signin': 'تسجيل الدخول'
  },
  'utility': {
    'maintenance': 'في الصيانة'
  },
  'faq': {
    'call': 'هل لديك أسئلة أخرى؟ الرجاء التواصل'
  },
  'ecommerce': {
    'products': 'منتجات',
    'filters': 'المرشحات',
    'collections': 'المجموعات',
    'priceRange': 'نطاق السعر',
    'customerReviews': 'آراء المستهلكين',
    'up': 'و اعلى',
    'brand': 'الماركة',
    'search': 'ابحث عن المنتج',
    'results': 'النتائج ({0} من {1})',
    'orders': 'الطلب #٪ s',
    'shipping': 'الشحن',
    'freeShipping': 'الشحن مجانا',
    'inStock': 'في المخزن',
    'quantity': 'كمية',
    'addToCart': 'أضف إلى السلة',
    'buyNow': 'اشتري الآن',
    'price': 'السعر',
    'about': 'حول هذا البند',
    'description': 'وصف',
    'reviews': 'المراجعات',
    'details': 'تفاصيل المنتج',
    'cart': 'عربة التسوق',
    'summary': 'ملخص الطلب',
    'total': 'مجموع',
    'discount': 'خصم',
    'subtotal': 'المجموع الفرعي',
    'continue': 'مواصلة التسوق',
    'checkout': 'الدفع'
  },
  'menu': {
    'search': 'بحث (اضغط على \'ctrl + /\' للتركيز)',
    'dashboard': 'لوحة القيادة',
    'logout': 'تسجيل خروج',
    'profile': 'الملف الشخصي',
    'blank': 'صفحة فارغة',
    'pages': 'الصفحات',
    'others': 'الآخرين',
    'email': 'البريد الإلكتروني',
    'chat': 'دردشة',
    'todo': 'لكى يفعل',
    'board': 'مجلس المهام',
    'users': 'المستخدمون',
    'usersList': 'قائمة',
    'usersEdit': 'تعديل',
    'ecommerce': 'التجارة الإلكترونية',
    'ecommerceList': 'منتجات',
    'ecommerceProductDetails': 'تفاصيل المنتج',
    'ecommerceOrders': 'الطلب #٪ s',
    'ecommerceCart': 'عربة التسوق',
    'auth': 'صفحات المؤلف',
    'authLogin': 'تسجيل الدخول / تسجيل الدخول',
    'authRegister': 'التسجيل / التسجيل',
    'authVerify': 'التحقق من البريد الإلكتروني',
    'authForgot': 'هل نسيت كلمة المرور',
    'authReset': 'إعادة تعيين كلمة المرور',
    'errorPages': 'صفحات الخطأ',
    'errorNotFound': 'غير موجود / 404',
    'errorUnexpected': 'غير متوقع / 500',
    'utilityPages': 'صفحات المرافق',
    'utilityMaintenance': 'اعمال صيانة',
    'utilitySoon': 'قريبا',
    'utilityHelp': 'أسئلة وأجوبة / مساعدة',
    'levels': 'مستويات القائمة',
    'disabled': 'القائمة معطلة',
    'docs': 'توثيق',
    'feedback': 'ردود الفعل',
    'support': 'الدعم',
    'landingPage': 'الصفحة المقصودة',
    'pricingPage': 'صفحة التسعير'
  },
  '$vuetify': {
    'badge': 'شارة',
    'close': 'قريب',
    'dataIterator': {
      'noResultsText': 'لا توجد سجلات مطابقة',
      'loadingText': 'جارٍ تحميل العناصر ...'
    },
    'dataTable': {
      'itemsPerPageText': 'عدد الصفوف في الصفحة:',
      'ariaLabel': {
        'sortDescending': 'مرتبة تنازلياً.',
        'sortAscending': 'تم الترتيب تصاعديًا.',
        'sortNone': 'لم يتم الفرز.',
        'activateNone': 'تنشيط لإزالة الفرز.',
        'activateDescending': 'تنشيط للترتيب تنازليًا.',
        'activateAscending': 'قم بالتفعيل للترتيب تصاعديًا.'
      },
      'sortBy': 'ترتيب حسب'
    },
    'dataFooter': {
      'itemsPerPageText': 'مواد لكل صفحة:',
      'itemsPerPageAll': 'الكل',
      'nextPage': 'الصفحة التالية',
      'prevPage': 'الصفحة السابقة',
      'firstPage': 'الصفحة الأولى',
      'lastPage': 'آخر صفحة',
      'pageText': '{0} - {1} من {2}'
    },
    'datePicker': {
      'itemsSelected': 'تم تحديد {0}',
      'nextMonthAriaLabel': 'الشهر القادم',
      'nextYearAriaLabel': 'العام القادم',
      'prevMonthAriaLabel': 'الشهر الماضى',
      'prevYearAriaLabel': 'السنة الماضية'
    },
    'noDataText': 'لا تتوافر بيانات',
    'carousel': {
      'prev': 'المرئية السابقة',
      'next': 'المرئي التالي',
      'ariaLabel': {
        'delimiter': 'شريحة الرف الدائري {0} من {1}'
      }
    },
    'calendar': {
      'moreEvents': '{0} أخرى'
    },
    'fileInput': {
      'counter': '{0} ملفات',
      'counterSize': '{0} من الملفات (إجمالي {1})'
    },
    'timePicker': {
      'am': 'AM',
      'pm': 'PM'
    },
    'pagination': {
      'ariaLabel': {
        'wrapper': 'التنقل بين الصفحات',
        'next': 'الصفحة التالية',
        'previous': 'الصفحة السابقة',
        'page': 'الانتقال إلى الصفحة {0}',
        'currentPage': 'الصفحة الحالية ، الصفحة {0}'
      }
    }
  }
}