import store from '../../store'

export default {
  state: {
    source_id: null,
    template_id: null,
    whatsapp_campaing_type: null,
    whatsapp_campaing_name: null,
    whatsapp_agenda_id:null,
    whatsapp_file_data:null,
    whatsapp_agenda_file_data:null,
    whatsapp_campaing_is_scheduled:null,
    whatsapp_campaing_scheduled:null
  },
  getters: {
  },
  mutations: {
    setSourceId(state, payload) {
      state.source_id = payload
    },
    setTemplateId(state, payload) {
      state.template_id = payload
    },
    setWhatsappCampaingType(state, payload) {
      state.whatsapp_campaing_type = payload
    },
    setWhatsappCampaingName(state, payload) {
      state.whatsapp_campaing_name = payload
    },
    setWhatsappAgendaId(state, payload) {
      state.whatsapp_agenda_id = payload
    },
    setWhatsappExcelFileData(state, payload) {
      state.whatsapp_file_data = payload
    },
    setWhatsappAgendaFileData(state, payload) {
      state.whatsapp_agenda_file_data = payload
    },
    setWhatsappCampaingIsSheduled (state, payload) {
      state.whatsapp_campaing_is_scheduled = payload
    },
    setWhatsappCampaingScheduled(state, payload) {
      state.whatsapp_campaing_scheduled = payload
    }
  },
  actions: {
    sourceId ({ commit }, data) {
      commit('setSourceId', data)
    },
    templateId ({ commit }, data) {
      commit('setTemplateId', data)
    },
    whatsappCampaingType ({ commit }, data) {
      commit('setWhatsappCampaingType', data)
    },
    whatsappCampaingName ({ commit }, data) {
      commit('setWhatsappCampaingName', data)
    },
    whatsappAgendaId ({ commit }, data) {
      commit('setWhatsappAgendaId', data)
    },
    whatsappExcelFileData({ commit }, data) {
      commit('setWhatsappExcelFileData', data)
    },
    whatsappAgendaFileData({ commit }, data) {
      commit('setWhatsappAgendaFileData', data)
    },
    whatsappCampaingIsSheduled  ({ commit }, data) {
      commit('setWhatsappCampaingIsSheduled', data)
    },
    whatsappCampaingScheduled ({ commit }, data) {
      commit('setWhatsappCampaingScheduled', data)
    }
  }
}