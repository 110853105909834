import store from '../../store'

export default {
  state: {
    file: null,
    name: null,
    correoEmisor: null,
    asunto: null,
    emisorNombre: null
  },
  getters: {
  },
  mutations: {
    setFile(state, payload) {
      state.file = payload
    },
    setName(state, payload) {
      state.name = payload
    },
    setcorreoEmisor(state, payload) {
      state.correoEmisor = payload
    },
    setasunto(state, payload) {
      state.asunto = payload
    },
    setemisorNombre(state,payload) {
      state.emisorNombre = payload
    }
    
  },
  actions: {
    fileData ({ commit }, data) {
      commit('setFile', data)
    },
    name ({ commit }, data) {
      commit('setName', data)
    },
    correoEmisor({ commit }, data) {
      commit('setcorreoEmisor', data)
    },
    asunto({ commit }, data) {
      commit('setasunto', data)
    },
    emisorNombre({ commit }, data) {
      commit('setemisorNombre', data)
    }
  }
}