import BackendApi from '@/services/backend.service'

export default [
  //WHATSAPP BUSINESS
  {
    path: '/whatsapp/config/account',
    name: 'whatsapp-configuration',
    component: () => import('@/pages/Services/Selector/CampaignConfigurator.vue')
  },
  {
    path: '/whatsapp/config/templates',
    name: 'whatsapp-template-list',
    component: () => import('@/pages/Services/Selector/CampaignConfigurator/Templates.vue')
  }
]
