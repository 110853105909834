import Vue from 'vue'
import Router from 'vue-router'
import BackendApi from '@/services/backend.service'
import { cancelActiveRequests } from '@/services/setupInterceptors'

// Routes
import ServicesRoutes from './services.routes'
import PagesRoutes from './pages.routes'
import UsersRoutes from './users.routes'
import WhatsappRoutes from './whatsapp.routes'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    redirect: '/auth/signin'
  }, 
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard/dashboard.vue')
  },
  ...ServicesRoutes,
  ...PagesRoutes,
  ...UsersRoutes,
  ...WhatsappRoutes,
  {
    path: '/blank',
    name: 'blank',
    component: () => import(/* webpackChunkName: "blank" */ '@/pages/BlankPage.vue')
  },
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  },
  
  {
    path: '/auth/RecoverUserPassword',
    name: 'forgot-password',
    component: () => import('@/pages/auth/RecoverUserPassword.vue'),
    meta: {
      requiresAuth: false 
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {

  const isAuthenticated = $cookies.get('user') && $cookies.get('services') && $cookies.get('token')

  if (to.name === 'auth-signin' && isAuthenticated) {
    // Si ya está autenticado y trata de ir a la página de inicio de sesión, redirige a la página de inicio.
    return next({ name: 'dashboard-analytics' })
  }

  if (!isAuthenticated && to.meta.requiresAuth) {
    // Si no está autenticado y trata de acceder a una ruta protegida, redirige a la página de inicio de sesión.
    return next({ name: 'auth-signin' })
  }

  const isAgentRoute = (path) => path.startsWith('/agent')
  const isSupervidorRoute = (path) => path.startsWith('/management/supervisor/chat')

  if (!(isAgentRoute(to.path) && isAgentRoute(from.path)) && !(isSupervidorRoute(to.path) && isSupervidorRoute(from.path)) ) {
    cancelActiveRequests()
  }

  return next()
  
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
