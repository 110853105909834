import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import AppModule from './app'

import Authenticate from './authenticate/'
import Users from './users/'
import Credits from './credits/'
import Campaings from './campaings/'
import Sms from './sms'
import sockets from './sockets'
import whatsapp_Api from './whatsapp-api'
import customizationMenu from './customizationMenu'

//Chat 
import chatActions from './chat/chat-actions.js'
import chatGeneral from './chat/chat-general.js'
import chatMessages from './chat/chat-messages.js'
import ticketsDrawer from './chat/ticket-drawer.js'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    authenticate: Authenticate,
    users: Users,
    campaings: Campaings,
    credits: Credits,
    sms: Sms,
    sockets,
    whatsapp_Api,
    customizationMenu,

    // Chat :
    ticketsDrawer,
    chatActions,
    chatGeneral,
    chatMessages
  }
})

export default store
