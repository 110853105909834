import { ValidationError, RequestError, SearchError } from '@/services/errorHandler.service'
import BackendApi from '@/services/backend.service'
import CustomException from '@/services/customException'

const state = {
  userSearchInput: '',
  isFetchingMessages: false,
  remainingChatTime: -1,
  chatIsNeedReactivate: false,
  messages: [],
  visibleMessagePerPage: 1,
  contextMessage: null,
  galeryMultimediaMessage: null,
  sendInputValue: '',
  sendMessageInputInfo: {
    is_responding: false,
    id: null,
    message_responded: null
  },
  isLoadingTogglePin: false
}

const getters = {
  ticket: (state, getters, rootState) => {
    const { selectedTicketPhone, ticketsByStatus } = rootState.ticketsDrawer

    if (!selectedTicketPhone) return null

    for (const { pinned, unpinned } of ticketsByStatus) {
      const ticketInPinned = pinned.find((ticket) => ticket.client.phone === selectedTicketPhone)

      if (ticketInPinned) {
        return ticketInPinned
      }

      const ticketInUnpinned = unpinned.find((ticket) => ticket.client.phone === selectedTicketPhone)

      if (ticketInUnpinned) {
        return ticketInUnpinned
      }
    }

    return null
  },
  statusName: (state, getters) => {
    if (!getters.ticket) return null

    const status_id = getters.ticket.status_id
    const idToStatus = { 1: 'pending', 2: 'in_attention', 3: 'success', 4: 'failed' }

    return idToStatus[status_id]
  },
  visibleMessages: (state) => {
    if (!state.messages) return null
    const MESSAGES_PER_PAGE = 35
    const NUM_VISIBLE_MESSAGES = MESSAGES_PER_PAGE * state.visibleMessagePerPage

    const visibleMessages = state.messages.slice(-NUM_VISIBLE_MESSAGES)

    return visibleMessages
  }
}

const mutations = {
  SET_USER_SEARCH_INPUT(state, payload) {
    state.userSearchInput = payload
  },
  SET_CONTEXT_MESSAGE(state, payload) {
    state.contextMessage = payload
  },
  SET_IS_FETCHING_MESSAGES(state, payload) {
    state.isFetchingMessages = payload
  },
  ADD_VISIBLE_MESSAGES_PAGE(state) {
    state.visibleMessagePerPage++
  },
  SET_VISIBLE_MESSAGES_PAGE(state, payload) {
    state.visibleMessagePerPage = payload
  },
  SET_REMAINING_CHAT_TIME(state, payload) {
    state.remainingChatTime = payload
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload
  },
  SET_CHAT_IS_NEED_REACTIVATE(state, payload) {
    state.chatIsNeedReactivate = payload
  },
  ADD_MESSAGE(state, payload) {
    state.messages.push(payload)
  },
  SET_IS_OPEN_FILE_PREVIEW(state, payload) {
    state.file_preview.is_open = payload
  },
  UPDATE_FILE_PREVIEWED(state, payload) {
    state.file_preview.file_previewed = payload
  },
  ADD_LOCAL_MESSAGE(state, { agent_name, local_message_id, content, customType }) {
    const localMessage = formatLocalMessage({ agent_name, content, customType }, local_message_id)

    state.messages.push(localMessage)
  },
  UPDATE_SEND_INPUT_VALUE(state, payload) {
    state.sendInputValue = payload
  },
  SET_GALERY_MULTIMEDIA_MESSAGE(state, payload) {
    state.galeryMultimediaMessage = payload
  },
  SET_IS_LOADING_TOGGLE_PIN(state, isLoadingTogglePin) {
    state.isLoadingTogglePin = isLoadingTogglePin
  },
  UPDATE_MESSAGE_ID_BY_LOCAL_ID(state, { message_sent_id, local_message_id }) {
    const message = findMessageById(local_message_id)

    if (message) message.id = message_sent_id
  }
}

const actions = {
  async getMessages({ commit, rootState, getters }, vueContext) {
    commit('SET_IS_FETCHING_MESSAGES', true)
    commit('SET_MESSAGES', [])

    try {
      const payload = {
        agent_id: rootState.ticketsDrawer.workgroupAgent.agent_id,
        client_phone: getters.ticket.client.phone,
        company_phone: rootState.ticketsDrawer.workgroupAgent.company_phone
      }

      const response = await BackendApi.post('/chat/all_messages', payload)

      if (!response?.data?.success || !response?.data?.data) {
        throw new RequestError(response)
      }

      commit('SET_REMAINING_CHAT_TIME', response.data.data.chatInfo.seconds_to_expiration)
      commit('SET_MESSAGES', response.data.data.messages)
      commit('SET_IS_FETCHING_MESSAGES', false)
    } catch (error) {
      commit('SET_IS_FETCHING_MESSAGES', false)
      CustomException.handleError({ error }, vueContext)
    }
  },
  async sendMessage({ state, commit, rootState, getters }, content, vueContext) {
    try {
      if (!content) {
        throw new ValidationError('No se ha pasado un parámetro', { content })
      }
      
      if (state.contextMessage) {
        const context = state.contextMessage

        context.content.payload.context = null
        content.payload.context = context
      }

      const { workgroupAgent } = rootState.ticketsDrawer
      const { name: agent_name, user_id } = workgroupAgent

      const local_message_id = Math.random().toString(36).substring(2, 10)

      commit('ADD_LOCAL_MESSAGE', { agent_name, local_message_id, content })
      commit('UPDATE_SEND_INPUT_VALUE', '')

      const apiPayload = {
        user_id: user_id,
        company: {
          name: workgroupAgent.company_name,
          phone: workgroupAgent.company_phone
        },
        client: {
          name: getters.ticket.client.name,
          phone: getters.ticket.client.phone
        },
        message: {
          context_id: state.contextMessage?.message_id || null,
          content: {
            type: content.type,
            ...content.payload
          }
        },
        ticket_id: getters.ticket.id
      }

      commit('SET_CONTEXT_MESSAGE', null)
      const response = await BackendApi.post('/chat/send_message', apiPayload)
      
      if (!response?.data?.success || !response?.data?.data?.message?.id) {
        throw new RequestError(response)
      }
      const message_sent_id = response.data.data.message.id

      commit('UPDATE_MESSAGE_ID_BY_LOCAL_ID', { message_sent_id, local_message_id })
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async handleNewMessage({ state, commit, getters }, newMessage) {
    const last_message = state.messages[state.messages.length - 1]

    if (newMessage.type !== 'inbound') return
    if (Number(newMessage.client.phone) === Number(getters.ticket.client.phone) && Number(last_message.id) !== Number(newMessage.id)) {
      const REMAINING_CHAT_TIME_SECONDS = 86400

      commit('SET_REMAINING_CHAT_TIME', REMAINING_CHAT_TIME_SECONDS)
      commit('SET_CHAT_IS_NEED_REACTIVATE', false)
      commit('ADD_MESSAGE', newMessage)
    }
  },
  async handleNewMessageStatus({ commit, state }, new_status_data) {
    const message_id = new_status_data.id
    const new_status = new_status_data.status

    const message = findMessageById(message_id)

    if (message) message.status = new_status
  },
  async handleNewMessageReaction({ commit, state }, new_reaction_data) {
    const message_id = new_reaction_data.id
    const message_reaction = new_reaction_data.reaction

    const message = findMessageById(message_id)

    if (message) message.reaction = message_reaction
  },
  async togglePinStatus({ commit }, { ticket, vueContext }) {
    if (!ticket) throw new ValidationError('No se ha encontrado el ticket para pinnear/unpinnear')

    commit('SET_IS_LOADING_TOGGLE_PIN', true)
    try {
      const response = await BackendApi.post('/chat/toggle_pin_status', { ticket_id: ticket.id, new_pin_status: !ticket.is_pinned })

      if (!response.data.success) throw new Error('No se ha confirmado el cambio de pin status')

      commit('SET_IS_LOADING_TOGGLE_PIN', false)
      commit('ticketsDrawer/SWITCH_TICKET_PIN_STATUS', { ticket }, { root: true })
    } catch (error) {
      commit('SET_IS_LOADING_TOGGLE_PIN', false)
      CustomException.handleError({ error }, vueContext)
    }
  }
}

function getLastMessageIsFirstOfType(state) {
  if (!state.messages || state.messages.length === 0) return true
  const lastMessage = state.messages[state.messages.length - 1]

  return lastMessage.type !== 'outbound'
}

function getCurrentDate() {
  const currentDate = new Date()

  currentDate.setHours(currentDate.getHours() - 5)
  const current_date_formatted = currentDate.toISOString().substring(0, 19).replace('T', ' ')

  return current_date_formatted
}

function findMessageById(local_message_id) {
  for (let index = state.messages.length - 1; index >= 0; index--) {
    const message = state.messages[index]

    if (message.id === local_message_id) {
      return message
    }
  }

  return null
}

function formatLocalMessage({ agent_name, content, customType }, local_message_id) {
  const message = {
    id: local_message_id,
    message_id: null,
    content: content,
    status: 'QUEUED',
    created_at: getCurrentDate(),
    type: customType || 'outbound',
    isFirstOfType: true,
    reaction: null,
    sender: {
      name: agent_name
    }
  }

  return message
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
