const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'black',
      message,
      timeout: 2500
    })
  })
}

const showError = ({ state, commit }, { error }) => {
  if (!error?.message) return
  if (state.toast.show) commit('hideToast')
  setTimeout(() => {
    commit('showToast', {
      color: 'red darken-2',
      message: error.message,
      timeout: 5000 
    })
  })
}

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'green darken-1',
      message,
      timeout: 5000
    })
  })
}

export default {
  showToast,
  showError,
  showSuccess
}
