export class ValidationError extends Error {
  details: any;
  displayedMessage: string;

  constructor(message: string, details?: any, displayedMessage?: string) {
    super(message)
    this.name = 'ValidationError'
    this.details = details
    this.displayedMessage = displayedMessage || ''
  }
}

export class RequestError extends Error {
  details: any;
  payload: any | null;

  constructor(request: any, payload: any | null = null) {
    super('Cannot obtain request or data from endpoint')
    this.name = 'RequestError'
    this.details = request
    this.payload = payload
  }
}

export class SearchError extends Error {
  payload: any;

  constructor(message: string, payload: any) {
    super(message)
    this.name = 'SearchError'
    this.payload = payload
  }
}