import store from '../../store'

export default {
  state: {
    halloweenTheme: 1,
    usersCreditsView: 1,
    usersTreeView: 1

  },
  getters: {
  },
  mutations: {
    setHalloweenTheme(state, payload) {
      state.halloweenTheme = payload
    },
    setUsersTreeView(state, payload) {
      state.usersTreeView = payload
    },
    setUsersCreditsView(state, payload) {
      state.usersCreditsView = payload
    }
    
  },
  actions: {
    halloweenTheme ({ commit }, data) {
      commit('setHalloweenTheme', data)
    },
    usersTreeView ({ commit }, data) {
      commit('setUsersTreeView', data)
    },
    usersCreditsView({ commit }, data) {
      commit('setUsersCreditsView', data)
    }
  }
}