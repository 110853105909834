<template>
  <v-app>
    
    <!-- Layout component -->
    
    <component :is="currentLayout" v-if="isRouterLoaded" :halloween-tematic="halloweenTematic" >
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>

    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
      <div class="text-center">{{ toast.message }}</div>
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">{{ $t('common.close') }}</v-btn>
    </v-snackbar>

    <customization-menu 
      v-if="isUsersRoute"
      @change-users-credits-visibility="changeUsersCreditsVisibility"
      @change-users-tree-view-visibility="changeUsersTreeViewVisibility"  
    />
  </v-app>
</template>

<script>
import CustomizationMenu from './components/demo/CustomizationMenu'
import { mapState } from 'vuex'

import config from './configs'

// Layouts
import defaultLayout from './layouts/DefaultLayout'
import landingLayout from './layouts/LandingLayout'
import simpleLayout from './layouts/SimpleLayout'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    landingLayout,
    simpleLayout,
    authLayout,
    errorLayout,
    CustomizationMenu
  },
  data() {
    return {
      halloweenTematic: null
    }
  },
  computed: {
    ...mapState('app', ['toast']),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    },
    isAuthSignRoute() {
      return this.$route.path === '/auth/signin'
    },
    isUsersRoute() {
      return this.$route.path === '/config/users'
    },
    isFilesRoute() {

      return this.$route.path === '/tools/filescloud'
    }
  },

  methods: {
    changeUsersCreditsVisibility(data) {
      this.$store.dispatch('usersCreditsView', data)
    },
    changeUsersTreeViewVisibility(data) {
      this.$store.dispatch('usersTreeView', data)
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>