import { ValidationError, RequestError, SearchError } from '@/services/errorHandler.service'
import BackendApi from '@/services/backend.service'
import CustomException from '@/services/customException'

//falta declarar este modulo????
const state = {
  isUploadingFile: null,
  isExpandMultimediaMessageDialog: false,
  uploadedFile: null,
  isDownloadingFile: false,
  isFetchingQuickReplies: false,
  quickReplies: []
}

const getters = {}

const mutations = {
  SET_IS_UPLOADING_FILE(state, payload) {
    state.isUploadingFile = payload
  },
  SET_IS_EXPAND_MULTIMEDIA_MESSAGE_DIALOG(state, payload) {
    state.isExpandMultimediaMessageDialog = payload
  },
  SET_UPLOADED_FILE(state, payload) {
    state.uploadedFile = payload
  },
  SET_IS_DOWNLOADING_FILE(state, payload) {
    state.isDownloadingFile = payload
  },
  SET_IS_FETCHING_QUICK_REPLIES(state, payload) {
    state.isFetchingQuickReplies = payload
  },
  SET_QUICK_REPLIES(state, payload) {
    state.quickReplies = payload
  }
}

const actions = {
  async getQuickReplies({ commit, rootState }, vueContext) {
    commit('SET_IS_FETCHING_QUICK_REPLIES', true)
    try {
      const workgroup_id = rootState.ticketsDrawer.workgroupAgent.workgroup_id
      const agent_id = rootState.ticketsDrawer.workgroupAgent.agent_id      
      const response = await BackendApi.get('/chat/quick_replies', {
        params: { workgroup_id, agent_id }
      })

      if (!response?.data?.success || !response?.data?.data) { 
        throw new RequestError(response)
      }
  
      commit('SET_QUICK_REPLIES', response.data.data)
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    } finally {
      commit('SET_IS_FETCHING_QUICK_REPLIES', false)
    }
  },
  
  async uploadFile({ commit }, file) {
    commit('SET_IS_UPLOADING_FILE', true)

    try {
      const formData = new FormData()

      formData.append('file', file)
      formData.append('name', file.name)
      
      const response = await BackendApi.post('/uploadFile', formData)

      commit('SET_IS_UPLOADING_FILE', false)

      return response
    } catch (error) {
      commit('SET_IS_UPLOADING_FILE', false)
      console.error(error)

      return false
    }
  },
  async uploadAndPreviewFile({ commit, dispatch }, { file, type, vueContext }) {
    commit('SET_IS_EXPAND_MULTIMEDIA_MESSAGE_DIALOG', true)
    try {      
      const response = await dispatch('uploadFile', file)

      if (!response || !response.data || !response.data.data ) {
        throw new ValidationError('Error del backend, no se encontró respuesta', { response })  
      }
      const uploadedFile = response.data.data

      if (!uploadedFile) {
        throw new ValidationError('No se ha logrado recuperar el arhchivo subido', { file, formData, uploadedFile })
      }

      if (type === 'visualMedia') {
        uploadedFile.type = file.type === 'video/mp4' ? 'video' : 'image'
      } else if (type === 'file') {
        uploadedFile.type = type 
      } else {
        throw new ValidationError('No se ha proporcionado el type', { type })
      }

      commit('SET_UPLOADED_FILE', uploadedFile)
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async downloadFile({ commit }, url, vueContext) {
    commit('SET_IS_DOWNLOADING_FILE', true)
    const date_now = Date.now()
    const nombreArchivoLocal = 'img_' + date_now + '.jpg'

    try {
      const response = await BackendApi.post('/chat/convert_image_to_base_64', { url })
      
      if (response) {
        // Crear la URL base64 y descargar el archivo
        const urlBase64 = `data:${response.headers['content-type']};base64,${response.data}`
        const link = document.createElement('a')

        link.href = urlBase64
        link.setAttribute('download', nombreArchivoLocal)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      
    } catch (error) {
      CustomException.handleError({ error }, vueContext)

    } finally {
      commit('SET_IS_DOWNLOADING_FILE', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}