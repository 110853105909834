<template>
  <div
    v-shortkey="['ctrl', '/']"
    class="d-flex flex-grow-1"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <img src="/logo.png" style="width: 150px; margin: 20px 45px 0px;">
      </template>

      <!-- Navigation menu -->
      <!-- <main-menu :menu="navigation.menu" /> -->
      <main-menu :menu="validateRole" />

    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card class="flex-grow-1 d-flex card_toolbar" :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']" :flat="!isToolbarDetached">
        <div class="d-flex flex-grow-1 align-center">
          <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
          
          <v-spacer></v-spacer>

          <!-- <toolbar-apps /> -->

          <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
            <!-- <toolbar-notifications /> -->
          </div>
          <toolbar-user />
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height" :fluid="!isContentBoxed">
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>

      <v-footer app inset>
        <v-spacer></v-spacer>
        <div class="overline no-uppercase">
          EnviaMas ® 2023
        </div>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BackendApi from '@/services/backend.service'
// navigation menu configurations
import config from '../configs'

import MainMenu from '../components/navigation/MainMenu'
import ToolbarUser from '../components/toolbar/ToolbarUser'
import * as Sentry from '@sentry/vue'
// import ToolbarApps from '../components/toolbar/ToolbarApps'
// import ToolbarNotifications from '../components/toolbar/ToolbarNotifications'

export default {
  components: {
    MainMenu,
    ToolbarUser
    // ToolbarApps,
    // ToolbarNotifications
  },
  props:{
    halloweenTematic:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawer: null,
      showSearch: false,
      navigation: config.navigation,
      menuNavigation:[],
      roles: {},
      chatRolesFetched: false
    }
  },  
  computed: {
    ...mapState('app', ['product', 'isContentBoxed', 'menuTheme', 'toolbarTheme', 'isToolbarDetached']),
    validateRole () {

      const role_user = $cookies.get('user')?.role_id ?? -1
      const isOwner = $cookies.get('chat_roles')?.isChatOwner ?? false
      const isSupervisor = $cookies.get('chat_roles')?.isChatSupervisor ?? false
      const isAgent = $cookies.get('chat_roles')?.isChatAgent ?? false
      
      let menu = []

      if (role_user === 1) {

        menu = config.navigation.menu.filter( (e) => (e.text !== 'Otros'))
      } else if (role_user === 2) {

        menu = config.navigation.menu.filter( (e) => (e.text !== 'Canales'))
      } else if (role_user > 2) {

        menu = config.navigation.menu.filter( (e) => (e.text !== 'Canales' && e.text !== 'Otros'))
      }
      //Deshabilitar Hipervision (No está en uso)
      menu[2].items[0].items = config.navigation.menu[2].items[0].items.filter( (e) => (e.text !== 'Hipervision'))
      //
      if (isOwner || isSupervisor) {
        if (!isOwner) {
          menu[2].items[0].items = config.navigation.menu[2].items[0].items.filter( (e) => (e.text !== 'Workgroups' && e.text !== 'Hipervision' && e.text !== 'Configuración' ))
        }
        if (!isSupervisor) {
          menu[2].items[0].items = config.navigation.menu[2].items[0].items.filter( (e) => (e.text !== 'Supervisar'))
        }

      }
      else {
        menu = menu.filter( (e) => (e.text !== 'Atención al cliente'))
      }

      if (!isAgent) {
        menu = menu.filter( (e) => (e.text !== 'Chat'))
      }

      return menu
    }
  },
  watch: {
    '$route'(to, from) {

      this.adjustDrawerVisibility()
    },
    beforeRouteEnter(to, from, next) {

      next((vm) => {

        vm.adjustDrawerVisibility()
      })
    }
  },

  created() {
    if (!$cookies.get('user')) {
      this.$store.dispatch('app/showError', { error: { message: 'La sesión ha caducado.' } })
      this.$router.push('/auth/signin')

      return
    }
    const user_email = $cookies.get('user').email

    this.identifyUser(user_email) // Smart Lock
    
    if (this.$route.path === '/tools/chat') {
      this.drawer = false
    }
    
  },
  methods: {
    identifyUser(email) {
      // SmartLoock :
      const smartlook = window.smartlook  // Conection Sucess

      if (smartlook) smartlook('identify', email)
      // Sentry :
      Sentry.setUser({ email: email })
    },
    filternavigation () {
      return config.navigation.menu.filter( (e) => (e.text !== 'Canales' /*&& e.text !== 'Otros' && e.text !== 'Chat'*/) )
    },
    toggleDrawer() {

      this.drawer = !this.drawer
    },
    adjustDrawerVisibility() {
 
      if (this.$route.path === '/tools/chat' || this.$route.path.startsWith('/tools/chat/workgroup')) {
        this.drawer = false 
      } else {
        this.drawer = true 
      }
    }
  }

}
</script>

<style scoped>
.buy-button {
  box-shadow: 1px 1px 18px #ee44aa;
}

.card_toolbar{
 background: transparent;
}

</style>
