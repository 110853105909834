import api from './api'

class BackendApi {

  constructor() {
    this.headers = {
      'Content-Type': 'application/json'
    }
  }
  get(endpoint, data, headers = this.headers) {
    return api.get(endpoint, data, headers)
  }

  post(endpoint, data, headers = this.headers) {
    return api.post(endpoint, data, headers)
  }

  put(endpoint, data, headers = this.headers) {
    return api.put(endpoint, data, headers)
  }

  update(endpoint, data, headers = this.headers) {
    return api.update(endpoint, data, headers)
  }

  delete(endpoint, data, headers = this.headers) {
    return api.delete(endpoint, data, headers)
  }
  
}

export default new BackendApi()