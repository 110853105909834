import backendApi from '@/services/backend.service'
import store from '../../store'
import router from '../../router'

export default {
  state: {
    loadingAuth: false,
    errorMessages: null
  },
  getters: {
    loading: (state) => state.loadingAuth,
    errorMessages: (state) => state.errorMessages
  },
  mutations: {
    setLoadingAuth(state, payload) {
      state.loadingAuth = payload
    },
    setErrorMessages(state, payload) {
      state.errorMessages = payload
    }
  },
  actions: {
    //Actualizar para traer tambien la data de isOwner, isAgent, isSupervisor????
    login ({ commit }, user) {
      commit('setLoadingAuth', true)
      backendApi.post('/login', {
        email: user.email,
        password: user.password
      }).then((response) => {

        if (response.data.success) {
          response.data.message = 'Su cuenta ha sido deshabilitada'
          if (response.data.data.user.status !== 0) {
            $cookies.set('user', response.data.data.user)
            $cookies.set('token', response.data.data.access_token)
            $cookies.set('services', response.data.data.services)
            $cookies.set('chat_roles', response.data.data.chat_roles)

            //add new cookie?

            store.commit('setErrorMessages', null)
            router.push({ path:'/dashboard/analytics' })
          } else {
            commit('setLoadingAuth', false)
            store.commit('setUser', null)
            store.commit('setErrorMessages', response.data.message)
          }
        }
      }).catch((error) => {
        let messageError = ''

        if (error.response.data.message) {
          messageError = error.response.data.message
        } else {
          messageError = 'No pudimos conectarnos con el servidor'
        }

        store.commit('setErrorMessages', messageError)
        commit('setLoadingAuth', false)
        
      })
    },
    logout() {
      $cookies.remove('user')
      $cookies.remove('token')
      $cookies.remove('services')
      //Nuevo cookie
      $cookies.remove('userRoles')

      router.push({ name: 'auth-signin' })
    }
  }
}