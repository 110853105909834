export default {
  state: {
    typesCampaing: [{ name: 'Individual', id: 0 }, { name: 'Agenda de contactos', id: 1 }, { name: 'Desde Excel', id: 2 }]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  }
}