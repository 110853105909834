import axiosInstance from './api'
import TokenService from './token.service'
import axios from 'axios' 

let activeRequests = []

const cancelActiveRequests = () => {
  activeRequests.forEach((cancelTokenSource) => {
    cancelTokenSource.cancel()//No enviar mensaje de cancelación fetch 
  })
  activeRequests = []
}

const setupInterceptors = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      
      const token = TokenService.getLocalAccessToken()
      
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token  
      }

      try {
      
        const cancelTokenSource = axios.CancelToken.source()

        config.cancelToken = cancelTokenSource.token
        activeRequests.push(cancelTokenSource)
  
      } catch (e) {
        console.error('ERROR EN LA CANCELACION', e)
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    async (res) => {
      activeRequests = activeRequests.filter(
        (source) => source.token !== res.config.cancelToken
      )

      return res
    },
    async (err) => {

      const originalConfig = err.config

      activeRequests = activeRequests.filter(
        (source) => source.token !== originalConfig.cancelToken
      )

      switch (err.response?.status) {
      case 498: {
        
        const rs = await axiosInstance.get('/refresh')
        const accessToken = rs.data.data.token
        
        TokenService.updateLocalAccessToken(accessToken)
        
        return axiosInstance(originalConfig)
      }
      case 499: {

        store.dispatch('logout')
        store.dispatch('app/showError', {
          message: 'Su sesión caducó, vuelva a iniciar sesión.',
          error: {
            message: ''
          } 
        })
        break
      }
      case 413: {
        store.dispatch('app/showError', {
          message: 'Oops! Archivo muy pesado.',
          error: {
            message: ''
          } 
        })
        break
      }
      case 404: {
        store.dispatch('app/showError', {
          message: 'Oops! Algo salió mal, vuelve a intentarlo.',
          error: {
            message: ''
          } 
        })
        break
      }
      }

      return Promise.reject(err)
    }
  )
}

export { setupInterceptors, cancelActiveRequests }
