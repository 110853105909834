import BackendApi from '@/services/backend.service'

export default [
  {
    path: '/services',
    name: 'service',
    component: () => import('@/pages/Services/Selector.vue')
  },
  {
    path: '/services/campaign/:service/type/:type',
    name: 'service-campaign-launch',
    component: () => import('@/pages/Services/Selector/CampaignLauncher.vue')
  },
  {
    path: '/services/campaign/:service/configuration',
    name: 'service-campaign-launch',
    component: () => import('@/pages/Services/Selector/CampaignLauncher.vue')
  },
  {
    path: '/management/supervisor',
    name: 'supervisor-dashboard',
    component: () => import('@/pages/Chat/supervisor/SupervisorDashboard.vue')
  },

  // WORKGROUPS
  {
    path: '/management/workgroups',
    name: 'workgroups',
    beforeEnter: (to, from, next) => {
      BackendApi.get('/chat/chat_user_state')
        .then((response) => {
          if (response.data.data.isChatOwner) {
            return next()
          }

          return next('/error')
        })
        .catch((error) => {
          next('/error')
        })
    },
    component: () => import('@/pages/Workgroups/Dashboard.vue')

  },
  //REPORTS
  {
    path: '/reports/campaigns',
    name: 'reports',
    component: () => import('@/pages/Reports/Reports.vue')

  },
  {
    path: '/reports/registers',
    name: 'registers',
    component: () => import('@/pages/Reports/Registers.vue')
  },
  {
    path: '/reports/sms/detail/:campaign_id',
    name: 'reports-sms',
    component: () => import('@/pages/Reports/DetailSmsReport.vue')
  },
  {
    path: '/reports/email/detail/:campaign_id',
    name: 'reports-email',
    component: () => import('@/pages/Reports/DetailEmailReport.vue')
  },
  {
    path: '/reports/whatsapp/detail/:campaign_id',
    name: 'reports-whatsapp',
    component: () => import('@/pages/Reports/DetailWhatsappReport.vue')
  }
]
